/**
 * Hamburgers plugin helper.
 *
 * @author Htmlstream
 * @version 1.0
 * @requires hamburgers.min.css
 *
 */
;(function($){
	'use strict';
	$(document).on('turbolinks:load', function() {
		$.HSCore.helpers.HSHamburgers = {

			/**
			 * Initialize 'hamburgers' plugin.
			 *
			 * @param String selector
			 *
			 * @return undefined;
			 */
			init: function(selector) {

				if( !selector || !$(selector).length ) return;

			  var hamburgers = $(selector),
			  		timeoutid;

			  hamburgers.each(function(i, el){

			  	var $this = $(this);

			  	if($this.closest('button').length) {
			  		$this.closest('button').get(0).addEventListener('click', function(e){

			  			var $self = $(this),
			  					$hamburger = $self.find(selector);

			  			if(timeoutid) clearTimeout(timeoutid);
			  			timeoutid = setTimeout(function(){

			  				$hamburger.toggleClass('is-active');

			  			}, 10);
			  			e.preventDefault();
			  		}, false);
			  	}
			  	else {
			  		$this.get(0).addEventListener('click', function(e){

			  			var $self = $(this);

			  			if(timeoutid) clearTimeout(timeoutid);
			  			timeoutid = setTimeout(function(){

			  				$self.toggleClass('is-active');

			  			}, 10);
			  			e.preventDefault();
			  		}, false);
			  	}

			  });

			}


		};
	});

})(jQuery);
